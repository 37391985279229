import React from "react";
import cx from "classnames";
import home from "../images/undraw_taking_notes_tjaf.svg";
import branding from "../images/noun_branding_1885335.svg";
import processing from "../images/noun_The Process_1885341.svg";
import modeling from "../images/noun_3d modeling_1885342.svg";
import Helmet from "react-helmet";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";



const StyledButton = ({ className, children, ...props }) => {
  className = cx(
    "py-2 px-4 bg-blue-900 hover:bg-blue-500 text-base text-white font-bold uppercase rounded shadow-md hover:-translate-1",
    className
  );
  return (
    <button className={className} {...props}>
      {children}
    </button>
  );
};

const Service = ({ title, url, children }) => {
  return (
    <div className="w-full sm:w-1/2 md:w-1/3 p-2">
      <Link
        to={url}
        className="text-2xl text-blue-900 hover:text-indogo-600 hover:underline"
      >
        {title}
      </Link>
      <p>{children}</p>
    </div>
  );
};

function Index({ data }) {
  const services = data.services.edges;

  return (
    <Layout
      className="gradient-background"
      headerClass="fixed top-0 z-50 bg-white"
      bodyClass=" "
    >
      <SEO title="Home" />
      <div className="relative min-h-screen pt-24 sm:pt-32 md:pt-64 -mt-12 sm:-mt-16 md:-mt-24 lg:-mt-28 mb-20 bg-size-5/6 md:bg-size-4/5 lg:bg-size-2/3 bg-right-top bg-no-repeat flex flex-col items-center">
        <div className="container mx-auto px-6 md:px-10 lg:px-16">
          <div className="w-full mt-10 mb-10 p-4 font-title font-normal uppercase self-start d-flex flex-col items-center justify-center text-center">
            <h1 className="text-3xl md:text-5xl text-blue-900 ">
              <img
                className="w-5/5 sm:w-4/5 md:w-3/5 mx-auto"
                alt="Logo Smart Fleet"
                src={"/logo_big.png"}
              />
            </h1>
            <div className="logo-tagline normal-case ">Fleet management that refreshes</div>
            <p className="text-base mt-5">
              Smart Fleet biedt dé nieuwe oplossing voor bedrijfswagenparken. We
              zorgen voor een A to Z-oplossing omtrent uw wagenpark op uw maat
              gemaakt.
            </p>
          </div>

          <div className="container w-3/4 sm:w-7/12 lg:w-5/12 xl:1/4 mt-10 self-center mx-auto">
            <div
              className="bg-white rounded flex flex-col sm:flex-row items-start sm:items-center text-sm p-4"
              style={{
                boxShadow:
                  "0 15px 35px rgba(50,50,93,.1), 0 5px 15px rgba(0,0,0,.07)"
              }}
            >
              <div className="flex-1">
                <p>
                  <strong>Telefoon: </strong>
                  <a href="tel:03.430.91.62">03.430.91.62</a>
                </p>
                <p>
                  <strong>Email: </strong>
                  <a href="mailto:info@smart-fleet.be">info@smart-fleet.be</a>
                </p>
              </div>
              <div className="flex-initial mt-6 sm:mt-0 ">
                <Link to="/contact">
                  <StyledButton>Contacteer ons</StyledButton>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div class="absolute mt-12 lg:mt-20 w-full absolute index-wave">
          <svg
            viewBox="0 0 1428 174"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xlink="http://www.w3.org/1999/xlink"
          >
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g
                transform="translate(-2.000000, 44.000000)"
                fill="#FFFFFF"
                fill-rule="nonzero"
              >
                <path
                  d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496"
                  opacity="0.100000001"
                ></path>
                <path
                  d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z"
                  opacity="0.100000001"
                ></path>
                <path
                  d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z"
                  id="Path-4"
                  opacity="0.200000003"
                ></path>
              </g>
              <g
                transform="translate(-4.000000, 76.000000)"
                fill="#FFFFFF"
                fill-rule="nonzero"
              >
                <path d="M0.457,34.035 C57.086,53.198 98.208,65.809 123.822,71.865 C181.454,85.495 234.295,90.29 272.033,93.459 C311.355,96.759 396.635,95.801 461.025,91.663 C486.76,90.01 518.727,86.372 556.926,80.752 C595.747,74.596 622.372,70.008 636.799,66.991 C663.913,61.324 712.501,49.503 727.605,46.128 C780.47,34.317 818.839,22.532 856.324,15.904 C922.689,4.169 955.676,2.522 1011.185,0.432 C1060.705,1.477 1097.39,3.129 1121.236,5.387 C1161.703,9.219 1208.621,17.821 1235.4,22.304 C1285.855,30.748 1354.351,47.432 1440.886,72.354 L1441.191,104.352 L1.121,104.031 L0.457,34.035 Z"></path>
              </g>
            </g>
          </svg>
        </div>

        <div className="bg-white w-full">
          <div className="container mx-auto">



          </div>
        </div>
      </div>

    </Layout>
  );
}

export const query = graphql`
  query {
    services: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content/services/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            path
          }
          excerpt
        }
      }
    }
  }
`;

export default Index;
